.slideShowImg {
  object-fit: cover;
  max-width: 100%;
  max-height: 400px;
  display: block;
  margin: 0 auto;
  padding: 0;
}
.imgCard {
  justify-content: center;
}
* {
  font-family: "Nanum Myeongjo", serif;
}
