.footer {
  height: 50px;
  background-color: black;
}

.copyRightText {
  text-align: center;
  color: white;
  line-height: 50px;
}
